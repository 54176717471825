import React, { useState, useEffect } from 'react'
import './appraisalDisplay.scss'
import APPRAISAL_ACTIONS from '../../../core/actions/appraisalActions.js'
import AppraisalDocument from './appraisalDocument.js'
import LoadingSpinner from '../../helpers/loadingspinner'
import { toast } from 'react-toastify'

function AppraisalDisplay(props) {
  const URLParams = new URLSearchParams(window.location.search)
  const debugParam = URLParams.get('debug')
  const vinParam = URLParams.get('vin')
  const mileageParam = URLParams.get('mileage')
  const zipParam = URLParams.get('zip_code')
  const collateralParam = URLParams.get('collateral_value')
  const autoAppraise = URLParams.get('auto_appraise')
  let accidentHistoryIdParam = null
  try {
    let tempId = parseInt(URLParams.get('accident_history_id'))
    if (tempId >= 0) {
      accidentHistoryIdParam = parseInt(URLParams.get('accident_history_id'))
    }
  } catch (e) {}
  const mileageRangeParam = URLParams.get('mileage_range')

  const [vinField, setVinField] = useState(vinParam ? vinParam : '')
  const [mileageField, setMileageField] = useState(mileageParam ? mileageParam : '')
  const [zipField, setZipField] = useState(zipParam ? zipParam : '')
  const [collateralField, setCollateralField] = useState(collateralParam ? collateralParam : '')

  const [accidentHistoryField, setAccidentHistoryField] = useState(
    accidentHistoryIdParam ? accidentHistoryIdParam : null,
  )
  const [accidentHistoryOptions, setAccidentHistoryOptions] = useState(null)

  //DEBUG FIELDS
  const [mileageRangeField, setMileageRangeField] = useState(
    mileageRangeParam ? mileageRangeParam : '',
  )

  const [errorMessage, setErrorMessage] = useState()

  const [loading, setLoading] = useState(true)
  const [appraisalInfo, setAppraisalInfo] = useState(null)
  const [appraisalComparables, setAppraisalComparables] = useState(null)

  // const [submittedVin, setSubmittedVin] = useState('')
  // const [submittedMileage, setSubmittedMileage] = useState('')
  // const [submittedZip, setSubmittedZip] = useState('')
  // const [submittedCollateral, setSubmittedCollateral] = useState('')
  // const [submittedAccidentHistory, setSubmittedAccidentHistory] = useState(null)

  let allowSubmit = vinField.length === 17 && zipField.length > 4
  useEffect(() => {
    setErrorMessage(null)
    APPRAISAL_ACTIONS.getAccidentHistoryOptions()
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res && res.results) {
          if (!accidentHistoryField) {
            setAccidentHistoryField(res.results[0].id)
          }
          setAccidentHistoryOptions(res.results)
          if (autoAppraise && allowSubmit) {
            submitAppraisal()
          } else {
            setLoading(false)
          }
        } else {
          setLoading(false)
          errorToast('There was an issue loading vehicle appraisal')
        }
      })
  }, [])
  const resetForm = () => {
    setAppraisalComparables(null)
    setAppraisalInfo(null)
  }
  const errorToast = message => {
    toast(message, { className: 'appraisal-display-error-toast', autoClose: false })
  }
  const submitAppraisal = () => {
    setLoading(true)
    APPRAISAL_ACTIONS.initiateVehicleAppraisal(
      vinField,
      zipField,
      parseInt(mileageField),
      parseInt(collateralField),
      parseInt(accidentHistoryField),
      parseInt(mileageRangeField),
    )
      .then(response => {
        if (response.status < 300 && response.status >= 200) {
          return response.json()
        } else {
          setLoading(false)
          errorToast('There was an issue loading vehicle appraisal')
        }
      })
      .then(res => {
        if (res && res.task_id) {
          if (res.status === 'SUCCESS') {
            setupPollingAppraisal(res.task_id)
          } else {
            if (res.detail) {
              errorToast(res.detail)
            } else {
              errorToast('There was an issue loading vehicle appraisal')
            }
            setLoading(false)
          }
        } else {
          errorToast('There was an issue loading vehicle appraisal')
          setLoading(false)
        }
      })
  }
  const stripNonAlphanumeric = val => {
    return val.replace(/[^a-zA-Z0-9]/g, '')
  }
  const stripNonNumeric = val => {
    console.log(val)
    return val.replace(/[^0-9]/g, '')
  }
  const getAppraisalComparables = appraisalId => {
    APPRAISAL_ACTIONS.getVehicleAppraisalComparables(appraisalId)
      .then(response => {
        if (response.status < 300 && response.status >= 200) {
          return response.json()
        } else {
          setLoading(false)
          errorToast('There was an issue loading vehicle appraisal')
        }
      })
      .then(res => {
        if (res && res.results) {
          setAppraisalComparables(res.results)
        }
        setLoading(false)
      })
  }
  let pollingAppraisal = null
  const pollFreq = 1000 // 1 Second
  const pollLimit = Math.abs((1000 * 60 * 15) / pollFreq) //15 minutes
  const setupPollingAppraisal = (appraisalId, pollCount = 0) => {
    pollingAppraisal = setTimeout(() => {
      APPRAISAL_ACTIONS.pollVehicleAppraisal(appraisalId)
        .then(response => {
          if (response.status < 300 && response.status >= 200) {
            return response.json()
          } else {
            setLoading(false)
            errorToast('There was an issue loading vehicle appraisal')
          }
        })
        .then(res => {
          if (res && res.state) {
            if (res.state === 'SUCCESS' && res.vehicle_details) {
              setAppraisalInfo(res)
              getAppraisalComparables(appraisalId)
            } else if (res.state === 'STARTED') {
              if (pollCount < pollLimit) {
                setupPollingAppraisal(appraisalId, pollCount + 1)
              } else {
                setLoading(false)
                errorToast('There was an issue loading vehicle appraisal')
              }
            } else if (res.state === 'FAILED' || res.state === 'FAILURE') {
              setLoading(false)
              if (res.detail) {
                errorToast(res.detail)
              } else {
                errorToast('There was an issue loading vehicle appraisal')
              }
            } else {
              setLoading(false)
              errorToast('There was an issue loading vehicle appraisal')
            }
          }
        })
    }, pollFreq)
  }

  const generateAccidentHistoryOptionsElements = () => {
    let accidentHistoryOptionElements = []
    for (let i = 0; i < accidentHistoryOptions.length; i++) {
      accidentHistoryOptionElements.push(
        <option value={accidentHistoryOptions[i].id}>{accidentHistoryOptions[i].label}</option>,
      )
    }
    return accidentHistoryOptionElements
  }
  return (
    <div className="appraisal-page page-content">
      {appraisalInfo && appraisalComparables ? (
        <div
          className="appraisal-page-reset"
          onClick={() => {
            resetForm()
          }}
        >
          {'Back'}
        </div>
      ) : (
        <React.Fragment />
      )}

      <h1 className="appraisal-page-title">
        iPacket <span>Trade</span> (BETA)
      </h1>
      {loading ? (
        <div className="appraisal-page-loading-animation">
          <LoadingSpinner loading />
        </div>
      ) : appraisalInfo && appraisalInfo.vehicle_details && appraisalComparables ? (
        <AppraisalDocument
          appraisalInfo={appraisalInfo}
          appraisalComparables={appraisalComparables}
          debug={debugParam}
        />
      ) : (
        <React.Fragment>
          <h2 className="appraisal-page-desc">
            Enter vehicle information to generate appraisal based on historical sales data
          </h2>
          {errorMessage ? (
            <div className="appraisal-page-error">{errorMessage}</div>
          ) : (
            <React.Fragment />
          )}

          <div className="appraisal-display">
            <div className="appraisal-display-form">
              <div className="appraisal-display-form-row">
                <div className="appraisal-display-input-container appraisal-display-input-container-vin">
                  <h1>VIN</h1>
                  <input
                    placeholder="VIN"
                    minlength="17"
                    type="text"
                    maxlength="17"
                    value={vinField}
                    onChange={e => {
                      setVinField(stripNonAlphanumeric(e.target.value))
                    }}
                  />
                </div>
              </div>
              <div className="appraisal-display-form-row">
                <div className="appraisal-display-input-container appraisal-display-input-container-mileage">
                  <h1>Mileage</h1>
                  <input
                    placeholder="Mileage"
                    type="text"
                    value={mileageField}
                    maxlength="8"
                    onChange={e => {
                      setMileageField(stripNonNumeric(e.target.value))
                    }}
                  />
                  <span className="appraisal-display-input-unit-end">mi</span>
                </div>
                <div className="appraisal-display-input-container appraisal-display-input-container-zip">
                  <h1>Zip Code</h1>
                  <input
                    placeholder="Zip Code"
                    minlength="5"
                    type="text"
                    maxlength="5"
                    value={zipField}
                    onChange={e => {
                      setZipField(stripNonNumeric(e.target.value))
                    }}
                  />
                </div>
              </div>
              <div className="appraisal-display-form-row">
                <div className="appraisal-display-input-container">
                  <h1>Collateral</h1>
                  <input
                    placeholder="Collateral"
                    type="text"
                    value={collateralField}
                    onChange={e => {
                      setCollateralField(stripNonNumeric(e.target.value))
                    }}
                  />
                </div>
                <div className="appraisal-display-input-container">
                  <h1>Accident History</h1>
                  <select
                    value={accidentHistoryField}
                    onChange={e => {
                      setAccidentHistoryField(e.target.value)
                    }}
                  >
                    {generateAccidentHistoryOptionsElements()}
                  </select>
                </div>
              </div>
              {debugParam ? (
                <React.Fragment>
                  <div className="appraisal-display-form-row">
                    <div className="appraisal-display-form-row-title">Debug Options</div>
                  </div>
                  <div className="appraisal-display-form-row">
                    <div className="appraisal-display-input-container">
                      <h1>Mileage Range</h1>
                      <input
                        placeholder="Mileage Range"
                        type="text"
                        value={mileageRangeField}
                        onChange={e => {
                          setMileageRangeField(stripNonNumeric(e.target.value))
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}

              <button
                className={`appraisal-display-submit`}
                disabled={!allowSubmit}
                onClick={() => {
                  submitAppraisal()
                }}
              >
                Appraise Vehicle
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
export default AppraisalDisplay
