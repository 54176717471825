import './appraisalDocument.scss'
import React, { useState, useEffect } from 'react'
const decimalFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' })
import CopyIcon from './copy-icon.js'
import PersonIcon from './person-icon.js'
import WarningIcon from './warning-icon.js'
import IPacketRingLogo from '../../../core/assets/images/icons/ipacketringlogo.js'

export default function AppraisalDocument({ appraisalInfo, appraisalComparables, debug }) {
  console.log('INFO', appraisalInfo)
  const { vehicle_details } = appraisalInfo
  const percentMSRP = (vehicle_details.current_retail_value / vehicle_details.original_msrp) * 100
  return (
    <div className="appraisal-document">
      {debug ? (
        <div className="appraisal-document-debug-info">
          <div className="appraisal-document-debug-api-return">
            {JSON.stringify(appraisalInfo, null, '\t')}
          </div>
          <div className="appraisal-document-debug-api-return-buttons">
            <button
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(appraisalInfo))
              }}
            >
              Copy
            </button>
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}

      <div className="appraisal-document-vehicle-details">
        <div className="appraisal-document-vehicle-details-header">
          <div className="appraisal-document-vehicle-details-price">
            <div className="appraisal-document-vehicle-details-price-retail">
              {`$${decimalFormatter.format(vehicle_details.current_retail_value)}`}
              <div className="appraisal-document-vehicle-details-price-retail-sub-heading">
                {`iPacket Market Value - ${percentMSRP.toFixed(2)}%`}
              </div>
            </div>
            <div className="appraisal-document-vehicle-details-price-separator"></div>
            <div className="appraisal-document-vehicle-details-price-msrp">
              {`$${decimalFormatter.format(vehicle_details.original_msrp)}`}
              <div className="appraisal-document-vehicle-details-price-msrp-sub-heading">
                Original MSRP
              </div>
            </div>
          </div>
          <div className="appraisal-document-vehicle-details-location">
            {vehicle_details.location}
          </div>
        </div>
        <div className="appraisal-document-vehicle-details-body">
          <div className="appraisal-document-vehicle-details-body-image">
            <IPacketRingLogo />
          </div>
          <div className="appraisal-document-vehicle-details-body-info">
            <div className="appraisal-document-vehicle-details-ymm">
              {`${vehicle_details.year} ${vehicle_details.make} ${vehicle_details.model}`}
            </div>
            {vehicle_details.trim ? (
              <div className="appraisal-document-vehicle-details-trim">
                {`${vehicle_details.trim}`}
              </div>
            ) : (
              <React.Fragment />
            )}

            <div className="appraisal-document-vehicle-details-mileage">
              {`Miles: ${decimalFormatter.format(vehicle_details.mileage)}`}
            </div>
            <CarfaxDisplay
              number_of_owners={vehicle_details.number_of_owners}
              accident_history={vehicle_details.accident_history}
              vehicle_use={vehicle_details.vehicle_use}
            />
          </div>
        </div>
      </div>

      <div className="appraisal-document-comparables">
        <div className="appraisal-document-comparables-title"> Market Comp Detail</div>
        <div className="appraisal-document-comparables-items">
          {appraisalComparables.map(comparable => {
            return <ComparableItem details={comparable} />
          })}
        </div>
      </div>
    </div>
  )
  if (vehicle_details) {
  }
}
function ComparableItem({ details }) {
  const {
    vin,
    year,
    make,
    model,
    trim,
    number_of_owners,
    accident_history,
    vehicle_use,
    location,
    mileage,
    sales_price,
    original_msrp,
    percent_msrp,
  } = details
  return (
    <div className="appraisal-document-comparables-item">
      <div className="appraisal-document-comparables-item-field">
        <div>
          <div className="appraisal-document-comparables-item-vin">{vin}</div>
          <div className="appraisal-document-comparables-item-ymmt">{`${year} ${make} ${model} ${trim}`}</div>
        </div>
      </div>
      <div className="appraisal-document-comparables-item-field">
        <CarfaxDisplay
          number_of_owners={number_of_owners}
          accident_history={accident_history}
          vehicle_use={vehicle_use}
        />
      </div>
      <div className="appraisal-document-comparables-item-field">
        <div className="appraisal-document-comparables-item-location">{location}</div>
      </div>
      <div className="appraisal-document-comparables-item-field">
        {`${decimalFormatter.format(mileage)} mi.`}
      </div>
      <div className="appraisal-document-comparables-item-field">
        <div className="appraisal-document-comparables-item-price">
          <div className="appraisal-document-comparables-item-price-retail">
            <div className="appraisal-document-comparables-item-price-title">{`SALE - ${percent_msrp}%`}</div>
            <div className="appraisal-document-comparables-item-price-value">{`$${decimalFormatter.format(
              sales_price,
            )}`}</div>
          </div>
          <div className="appraisal-document-comparables-item-price-separator"></div>
          <div className="appraisal-document-comparables-item-price-msrp">
            <div className="appraisal-document-comparables-item-price-title">{`MSRP`}</div>
            <div className="appraisal-document-comparables-item-price-value">{`$${decimalFormatter.format(
              original_msrp,
            )}`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CarfaxDisplay({ number_of_owners, accident_history, vehicle_use }) {
  return (
    <div className="appraisal-document-carfax">
      <div className="appraisal-document-carfax-title">CARFAX</div>
      <div className="appraisal-document-carfax-owners">
        <PersonIcon />
        {number_of_owners ? number_of_owners : 'N/A'}
      </div>
      <div className="appraisal-document-carfax-accidents">
        <WarningIcon />
        {accident_history !== 'No Accidents Reported' ? 1 : 0}
      </div>
      <div className="appraisal-document-carfax-use">{vehicle_use ? vehicle_use : 'N/A'}</div>
    </div>
  )
}
